import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import { AuthUtils } from '@core/helpers';
import jwtDecode from 'jwt-decode';
import { getMacroeconomicsValuesOnLocal, saveMacroeconomicsValuesOnLocal } from '@core/utils/local-storage-data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserType } from '@core/constants/users.constant';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class MacroeconomicsService {
  constructor(private http: HttpClient,) {
    
  }


  saveMacroeconomics(data:any, params:any): Observable<any>  {
    return this.http.post(`${baseUrl}/api/macroeconomics`, data, {params: params});
  }

  updateMacroeconomics(data:any, projectId:any, params:any): Observable<any>  {
    return this.http.put(`${baseUrl}/api/macroeconomics/${projectId}`, data, {params: params});
  }
  getMacroeconomicsInfo(projectId:any): Observable<any>  {
    return this.http.get(`${baseUrl}/api/macroeconomics/${projectId}`);
  }

  getEuro(): Observable<any>  {
    return this.http.get(`https://luker-rest.imagineapps.co/eur`,);
  }
  getCocoa(): Observable<any>  {
    return this.http.get(`https://luker-rest.imagineapps.co/cocoa-contracts`);
  }
  getPound(): Observable<any>  {
    return this.http.get(`${baseUrl}/api/auth/token/`);
  }

  getDolar(): Observable<any> {
    return this.http.get(`https://luker-rest.imagineapps.co/trm`);
  }

  getDolarToPound(): Observable<any> {
    return this.http.get(`https://api.fastforex.io/fetch-one?from=USD&to=GBP&api_key=demo`);
  }

  getPrices(dolar:any,cocoa:any,cocoaPremium:any){
    return this.http.get(`${baseUrl}/api/costos/materia_prima?dollarPrice=${dolar}&cacaoPrice=${cocoa}&addCacaoPrice=${cocoaPremium}`);
  }

  getMacroeconomicsValues(){
    return getMacroeconomicsValuesOnLocal();
  }

  saveMacroeconomicsValues(values:any){
    return saveMacroeconomicsValuesOnLocal(values);
  }

  getCostos(){
    return this.http.get(`${baseUrl}/api/costos`,);
  }

  saveMacroeconomicsVersionInfo(versionId:any, data:any){
    return this.http.put(`${baseUrl}/api/project_brief_version/${versionId}`, data);
  }
}
